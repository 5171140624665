



















































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Message} from 'element-ui';
import {db} from '@/firebase';

@Component
export default class LearningFeatureBuilderReview extends Vue {
    loading = false;
    items: any[] = [];
    questions: { name: string, items: any[], visible: boolean } = {name: '', items: [], visible: false};
    popoverDelete: any = {};

    showQuestions(name: string, ids: string[] = []) {
        this.questions.name = name;
        this.questions.items = [];
        for (const id of ids) {
            db.collection('LearningFeature').doc(id).get().then((DocumentSnapshot) => {
                this.questions.items.push(DocumentSnapshot.data());
            });
        }
        this.questions.visible = true;
    }

    removeExam(index: number, id: string) {
        db.collection('LearningFeatureExams').doc(id).delete()
            .then(() => {
                Message({
                    message: 'Successfully removed the exam',
                    type: 'success',
                });
                this.items.splice(index, 1);
                this.popoverDelete[id] = false;
            })
            .catch(() => Message({
                message: 'Something went wrong, please try again',
                type: 'error',
            }));
    }

    created() {
        this.loading = true;
        db.collection('LearningFeatureExams').get().then((querySnapshot: any) => {
            querySnapshot.forEach((item: any) => {
                const examId = {objectId: item.id};
                const exam = item.data() || {};
                this.items.push({...exam, ...examId});
            });
            this.loading = false;
        });
    }

}
